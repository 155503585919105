import type { Ref } from 'vue';
import {
  computed,
  watch,
} from 'vue';

import {
  egsBlockTypeGeo,
} from '@leon-hub/api-sdk';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import type { EgsGameErrorData } from 'web/src/modules/casino/types';
import type { EgsGame } from 'web/src/modules/egs/types';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import {
  AnalyticsModalType,
} from 'web/src/modules/dialogs/enums';
import { useI18n } from 'web/src/modules/i18n/composables';

import type { FetchStartError } from './types';

export interface CasinoGameErrorComposable {
  errorData: Ref<EgsGameErrorData | undefined>;
}

export default function useCasinoGameError(
  game: Ref<EgsGame | undefined>,
  isDemoUnavailable: Ref<boolean>,
  startGameError: Ref<FetchStartError | undefined>,
  reloadCounter: Ref<number>,
): CasinoGameErrorComposable {
  const { $translate } = useI18n();
  const analytics = useAnalytics();

  const errorData = computed<EgsGameErrorData | undefined>(() => {
    if (process.env.VUE_APP_PRERENDER || !game.value) {
      return undefined;
    }

    if (game.value.blockType === egsBlockTypeGeo || game.value.group.blockType === egsBlockTypeGeo) {
      return {
        type: 'game-block',
        title: $translate('WEB2_EGS_GAME_ERROR_UNAVAILABLE_TITLE').value,
        text: $translate('WEB2_EGS_GAME_BLOCKED').value,
        button: {
          action: 'close',
          text: $translate('WEB2_EGS_GAME_ERROR_ALL_GAMES_BTN').value,
        },
      };
    }

    if (game.value.isUnavailable) {
      return {
        type: 'unavailable',
        title: $translate('WEB2_EGS_GAME_ERROR_UNAVAILABLE_TITLE').value,
        text: $translate('WEB2_GAME_UNAVAILABLE').value,
        button: {
          action: 'close',
          text: $translate('WEB2_EGS_GAME_ERROR_ALL_GAMES_BTN').value,
        },
      };
    }

    if (process.env.VUE_APP_LAYOUT_DESKTOP && isDemoUnavailable.value) {
      return {
        type: 'demo',
        title: $translate('WEB2_CASINO_DEMO_UNAVAILABLE_HEADING').value,
        text: $translate('WEB2_CASINO_DEMO_UNAVAILABLE_TEXT').value,
        button: {
          action: 'login',
          text: $translate('WEB2_CASINO_DEMO_UNAVAILABLE_BTN').value,
        },
      };
    }

    if (!startGameError.value) {
      return undefined;
    }

    if (startGameError.value.code === 'EGS_USER_IS_BLOCKED') {
      return {
        type: 'game-block',
        title: $translate('WEB2_EGS_GAME_ERROR_UNAVAILABLE_TITLE').value,
        text: startGameError.value.message,
        button: {
          action: 'close',
          text: $translate('WEB2_EGS_GAME_ERROR_ALL_GAMES_BTN').value,
        },
      };
    }

    if (startGameError.value.code === 'RESTRICTED_COUNTRY') {
      return {
        type: 'provider-block',
        title: $translate('WEB2_EGS_GAME_ERROR_PROVIDER_UNAVAILABLE_TTL').value,
        text: startGameError.value.message,
        button: {
          action: 'providers',
          text: $translate('WEB2_EGS_GAME_ERROR_ALL_PROVIDERS_BTN').value,
        },
      };
    }

    if (startGameError.value.code === 'GAME_NOT_FOUND') {
      return {
        type: 'unavailable',
        title: $translate('WEB2_EGS_GAME_ERROR_UNAVAILABLE_TITLE').value,
        text: startGameError.value.message,
        button: {
          action: 'close',
          text: $translate('WEB2_EGS_GAME_ERROR_ALL_GAMES_BTN').value,
        },
      };
    }

    if (startGameError.value.code === 'BETS_SELF_EXCLUSION_ERROR') {
      return {
        type: 'unavailable',
        title: $translate('WEB2_EGS_GAME_ERROR_UNAVAILABLE_TITLE').value,
        text: startGameError.value.message,
        button: {
          action: 'close',
          text: $translate('WEB2_CLOSE').value,
        },
      };
    }

    const isReload = reloadCounter.value === 0;

    return {
      type: 'unavailable',
      title: $translate('WEB2_EGS_GAME_ERROR_UNAVAILABLE_TITLE').value,
      text: startGameError.value.message,
      button: {
        action: isReload ? 'refresh' : 'close',
        text: isReload
          ? $translate('WEB2_EGS_GAME_ERROR_REFRESH_BTN').value
          : $translate('WEB2_EGS_GAME_ERROR_ALL_GAMES_BTN').value,
      },
    };
  });

  watch(startGameError, (newValue) => {
    if (newValue) {
      analytics.push(AnalyticsEvent.MODAL_ERROR_MESSAGES, {
        [AnalyticsModalType.WARNING_EGS]: newValue.message,
      });
    }
  }, {
    deep: true,
    immediate: true,
  });

  return {
    errorData,
  };
}
