<script lang="ts" setup>
import { ButtonKind } from '@leon-hub/module-buttons';
import { IconSize } from '@leon-hub/icons';

import { DefaultModal } from 'web/src/components/Modal/DefaultModal';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import { ModalWidth } from 'web/src/components/Modal/enums';
import CasinoGamePromotionsModalTimer
  from 'web/src/modules/casino/submodules/game/views/CasinoGamePromotionsModal/components/CasinoGamePromotionsModalTimer/CasinoGamePromotionsModalTimer.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { VCheckbox } from 'web/src/components/Checkbox';
import SButton from 'web/src/components/Button/SButton/SButton.vue';

import type { CasinoGamePromotionsModalProps } from './types';
import { useCasinoGamePromotionsModal } from './composables';

const props = withDefaults(defineProps<CasinoGamePromotionsModalProps>(), {
});

const {
  selector,
  iconClose,
  isEnded,
  checkboxIsChecked,
  prizePool,
  casinoModalData,
  isVisible,
  onCheckboxChange,
  onClose,
  onReadMore,
  promotionEnd,
} = useCasinoGamePromotionsModal(props);
</script>

<template>
  <ModalPortal v-auto-id="'CasinoGamePromotionsModal'"
    v-if="casinoModalData && isVisible"
    :selector="selector"
  >
    <DefaultModal
      is-top-bar-hidden
      :is-alert="!!'1'"
      :width="ModalWidth.SMALL_MINUS"
      :class="$style['promotion-modal']"
      @close="onClose"
    >
      <template #header>
        <div
          :class="$style['promotion-modal__header']"
          :style="{ backgroundImage: `url(${casinoModalData?.promotions[0].bannerUrl})` }"
        >
          <CasinoGamePromotionsModalTimer
            v-if="!isEnded"
            :timestamp="casinoModalData?.promotions[0].endDateNumber || 0"
            @end="promotionEnd"
          />
        </div>

        <SButton
          v-if="null && null"
          :class="$style['promotion-modal__button-close']"
          :icon-name="iconClose"
          :icon-size="IconSize.SIZE_24"
          kind="secondary"
          @click="onClose"
        />
        <VButton
          v-else
          :class="$style['promotion-modal__button-close']"
          :icon-name="iconClose"
          :icon-size="IconSize.SIZE_24"
          :kind="ButtonKind.TRANSPARENT"
          @click="onClose"
        />
      </template>
      <template #content>
        <div :class="$style['promotion-modal__content']">
          <div :class="$style['promotion-modal__title']">
            {{ casinoModalData?.promotions[0].name }}
          </div>
          <div :class="$style['promotion-modal__prize']">
            <span
              v-if="isEnded"
              :class="$style['promotion-modal__finished']"
            >
              {{ $t('WEB2_GAME_PROMOTIONS_MODAL_ENDED') }}
            </span>
            <template v-else>
              <div :class="$style['promotion-modal__prize-title']">
                {{ $t('WEB2_GAME_PROMOTIONS_MODAL_PRIZE_POOL') }}
              </div>
              <div
                :class="$style['promotion-modal__prize-count']"
              >
                {{ prizePool }}
              </div>
            </template>
          </div>
        </div>

        <div
          v-if="null"
          :class="$style['promotion-modal__buttons']"
        >
          <SButton
            v-if="!isEnded"
            full-width
            :label="$t('WEB2_GAME_PROMOTIONS_MODAL_PLAY_NOW')"
            kind="primary"
            @click="onClose"
          />
          <SButton
            full-width
            :label="isEnded ? $t('WEB2_GAME_PROMOTIONS_MODAL_RESULTS') : $t('WEB2_GAME_PROMOTIONS_MODAL_READ_MORE')"
            kind="tertiary-secondary"
            @click="onReadMore"
          />
        </div>
        <div
          v-else
          :class="$style['promotion-modal__buttons']"
        >
          <VButton
            v-if="!isEnded"
            full-width
            :label="$t('WEB2_GAME_PROMOTIONS_MODAL_PLAY_NOW')"
            :kind="ButtonKind.PRIMARY"
            @click="onClose"
          />
          <VButton
            full-width
            :label="isEnded ? $t('WEB2_GAME_PROMOTIONS_MODAL_RESULTS') : $t('WEB2_GAME_PROMOTIONS_MODAL_READ_MORE')"
            :kind="ButtonKind.SECONDARY"
            @click="onReadMore"
          />
        </div>

        <VCheckbox
          v-if="!isEnded"
          :checked="checkboxIsChecked"
          :class="$style['promotion-modal__checkbox']"
          :label="$t('WEB2_GAME_PROMOTIONS_MODAL_HIDE')"
          @change="onCheckboxChange"
        />
      </template>
    </DefaultModal>
  </ModalPortal>
</template>

<style lang="scss" module>
.promotion-modal {
  &__header {
    @include casino-game-promotion-modal-header;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  &__finished {
    @include casino-game-promotion-modal-finished;
  }

  &__content {
    @include casino-game-promotion-modal-content;

    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding-top: 16px;

    &:before {
      @include casino-game-promotion-modal-content-before;

      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
      content: '';
    }
  }

  &__title {
    @include casino-game-promotion-modal-title;

    z-index: 1;
  }

  &__prize {
    z-index: 1;
    display: inline-flex;
    flex-direction: column;
    row-gap: 4px;
    align-items: center;

    &-title {
      @include casino-game-promotion-modal-prize-title;
    }

    &-count {
      @include casino-game-promotion-modal-prize-count;
    }
  }

  &__buttons {
    @include casino-game-promotion-modal-buttons;

    display: flex;
    flex-direction: column;
  }

  &__button {
    &-close {
      @include casino-game-promotion-modal-close-button;

      position: absolute;
    }
  }

  &__checkbox {
    position: relative;
    z-index: 1;
    display: inline-flex;
  }
}
</style>
