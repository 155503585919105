import { useCasinoActiveGame } from 'web/src/modules/casino/submodules/game/composables';
import { useLobbyStore } from 'web/src/modules/lobby/store';
import windowOpen from 'web/src/utils/windowOpen';

import type { OpenGameInNewWindowComposable } from './types';
import useCasinoGameExitUrls from './useCasinoGameExitUrls';

export default function useOpenGameInNewWindowWeb(): OpenGameInNewWindowComposable {
  if (!process.env.VUE_APP_PLATFORM_WEB) {
    throw new Error('Only WEB platform!');
  }

  const { getNewWindowUrl } = useCasinoGameExitUrls();
  const { setVisitedGame } = useLobbyStore();
  const { activeGame } = useCasinoActiveGame();

  let gameWindow: Maybe<Window> = null;

  function closeGameWindow(): void {
    if (gameWindow) {
      gameWindow.close();
      gameWindow = null;
    }
  }

  function onFocusBack(): void {
    setVisitedGame(activeGame.value);
    window.removeEventListener('focus', onFocusBack);
  }

  function openGameInNewWindow(gameId: string, isDemo = false): void {
    closeGameWindow();

    gameWindow = windowOpen(getNewWindowUrl(gameId, isDemo));
    if (process.env.VUE_APP_RENDERING_CSR) {
      window.addEventListener('focus', onFocusBack);
    }
  }

  return {
    openGameInNewWindow,
  };
}
