<script lang="ts" setup>
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import vDataTestUnit from 'web/src/testing/directives/vDataTestUnit';

import type { CasinoGameDescriptionEmits, CasinoGameDescriptionProps } from './types';
import { useCasinoGameDescription } from './composables';

const props = withDefaults(defineProps<CasinoGameDescriptionProps>(), {});
const emit = defineEmits<CasinoGameDescriptionEmits>();

const {
  isButtonVisible,
  gameDescription,
  buttonProperties,
} = useCasinoGameDescription(props);
</script>

<template>
  <div v-auto-id="'CasinoGameDescription'"
    :class="$style['casino-game-footer']"
  >
    <h2
      v-if="game.name"
      v-data-test="{ el: 'casino-game-footer-title' }"
      :class="$style['casino-game-footer__title']"
    >
      {{ $t('WEB2_ABOUT_GAME') }} {{ game.name }}
    </h2>
    <div :class="$style['casino-game-footer__description']">
      <div
        v-if="game.statistics.length"
        :class="$style['casino-game-footer__table']"
      >
        <div
          v-for="(item, index) in game.statistics"
          :key="index"
          :class="$style['casino-game-footer__table-item']"
        >
          <span
            v-data-test="{ el: 'casino-game-footer__table-title' }"
            :class="$style['casino-game-footer__table-title']"
          >
            {{ item.name }}
          </span>
          <span
            v-data-test="{ el: 'casino-game-footer__table-text' }"
            :class="$style['casino-game-footer__table-text']"
          >
            {{ item.value }}
          </span>
        </div>
      </div>
      <VDynamicContent
        v-data-test="{ el: 'casino-game-description' }"
        :content="gameDescription"
        :class="$style['casino-game-footer__text']"
      />
    </div>
    <VButton
      v-if="isButtonVisible"
      v-data-test="{ el: 'casino-game-description__show-more-button' }"
      v-data-test-unit="{ el: 'casino-game-description__show-more-button' }"
      v-bind="buttonProperties"
      :class="$style['casino-game-footer__toggle-button']"
      @click="emit('toggle')"
    />
  </div>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .casino-game-footer {
    display: inline-block;
    margin-bottom: 16px;

    @if $isDesktop {
      width: 100%;
      padding: 16px;
      margin: 0;
      background-color: var(--Layer1);
      border-radius: 5px;
    }

    &__title {
      margin: if($isDesktop, 0, 18px 16px);
      color: var(--TextDefault);

      @if $isDesktop {
        @include medium\18\28;
      } @else {
        @include medium\18\20;
      }
    }

    &__description {
      padding: if($isDesktop, 0, 0 16px 16px);

      @if $isDesktop {
        background-color: var(--Layer1);
        border-radius: 5px;
      }
    }

    &__text {
      @include regular\13\20;

      color: var(--TextPrimary);
    }

    &__toggle-button {
      margin: 0 16px;

      @if $isDesktop {
        display: none;
      }
    }

    &__table {
      display: flex;
      flex-wrap: wrap;

      @if $isDesktop {
        margin: 8px 0;
      }

      @media only screen and (min-device-width: 320px) and (max-device-width: 430px) {
        display: grid;
        grid-gap: 16px 2px;
        margin-bottom: 16px;

        &-item {
          width: auto;
          margin: 0;
        }
      }

      @media only screen and (min-device-width: 320px) and (max-device-width: 380px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media only screen and (min-device-width: 381px) and (max-device-width: 430px) {
        grid-template-columns: repeat(4, 1fr);
      }

      &-item {
        @include regular\13\20;

        display: flex;
        flex-direction: column;
        width: if($isDesktop, 97px, 92px);
        height: 40px;
        margin: if($isDesktop, 0 4px 0, 0 4px 16px 0);

        @if $isDesktop {
          justify-content: flex-start;
          text-align: left;
        }
      }

      &-title {
        overflow: hidden;
        color: var(--TextSecondary);
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &-text {
        color: var(--TextDefault);
      }
    }
  }
}
</style>
