import type { ComputedRef, Ref } from 'vue';
import {
  computed, onMounted, ref, toRef, watch,
} from 'vue';
import { useRouter } from 'vue-router';

import { CasinoRouteName, RouteName } from '@leon-hub/routing-config-names';
import type { AnyIconName } from '@leon-hub/icons';
import { IconName } from '@leon-hub/icons';
import { doMarkAsShownMutation, getLinkedPromotionsByEgsGame } from '@leon-hub/api-sdk';

import { ModalSelector } from 'web/src/modules/core/enums';
import { useCurrency, useFormatMoney } from 'web/src/modules/money/composables';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useRouterStore } from 'web/src/modules/core/store';

import type { CasinoGamePromotionsModalProps, LinkedPromotionsByEgsGameDocument } from '../types';

export interface CasinoGamePromotionsModalComposable {
  selector: ModalSelector;
  iconClose: AnyIconName;
  prizePool: ComputedRef<string>;
  casinoModalData: Ref<LinkedPromotionsByEgsGameDocument>;
  isVisible: Ref<boolean>;
  isEnded: Ref<boolean>;
  checkboxIsChecked: Ref<boolean>;
  onCheckboxChange: () => void;
  onClose: () => Promise<void>;
  onReadMore: () => void;
  promotionEnd: () => void;
}

export default function useCasinoGamePromotionsModal(
  props: CasinoGamePromotionsModalProps,
): CasinoGamePromotionsModalComposable {
  const api = useGraphqlClient();
  const router = useRouter();
  const formatMoney = useFormatMoney();
  const { currency } = useCurrency();
  const currentRouteName = toRef(useRouterStore(), 'currentRouteName');

  const selector = ModalSelector.BODY;

  const isEnded = ref(false);
  const casinoModalData = ref();
  const isVisible = ref(false);
  const checkboxIsChecked = ref(false);

  const iconClose = process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? IconName.SLOTT_CROSS : IconName.CROSS;

  const prizePool = computed(() => formatMoney(
    casinoModalData.value?.jackpot?.amount.value || 0,
    {
      currency: casinoModalData.value?.jackpot?.amount.currency || currency.value,
      reduceAmount: 1_000_000_000,
    },
  ));

  function onCheckboxChange() {
    checkboxIsChecked.value = !checkboxIsChecked.value;
  }
  async function onClose() {
    if (checkboxIsChecked.value && casinoModalData.value?.promotions) {
      await doMarkAsShownMutation(api,
        (node) => node.mutations.promotions.markAsShown,
        {
          options: {
            actionUrl: casinoModalData.value.promotions[0].actionUrl,
            providerId: props.activeGame.group.id,
            gameId: props.activeGame.group.name,
            hideGameForever: true,
          },
        },
        { silent: true });
    }
    casinoModalData.value = undefined;
  }

  function onReadMore() {
    if (isEnded.value) {
      void router.push({
        name: RouteName.PROMOTION_DETAILS,
        params: {
          categoryId: props.activeGame.group.id,
          actionUrl: props.activeGame.group.name,
        },
        query: {
          scrollToTerms: 'true',
        },
      });
    } else {
      void router.push({
        name: RouteName.CMS_PROMO_TERMS,
        params: {
          cmsKey: casinoModalData.value?.promotions[0].termsOfActions,
        },
      });
    }
  }

  function promotionEnd() {
    isEnded.value = true;
  }

  async function init() {
    const result = await getLinkedPromotionsByEgsGame(api,
      (node) => node.queries.promotions.getLinkedPromotionsByEgsGame,
      {
        options: {
          providerId: props.activeGame.group.id,
          gameId: props.activeGame.group.name,
        },
      },
      { silent: true });

    if (result?.promotions?.length) {
      casinoModalData.value = result;
    }
  }

  watch(currentRouteName, (newValue) => {
    isVisible.value = newValue === CasinoRouteName.CASINO_GAME;
  }, { deep: true, immediate: true });

  onMounted(init);

  return {
    selector,
    iconClose,
    isEnded,
    checkboxIsChecked,
    prizePool,
    casinoModalData,
    isVisible,
    onCheckboxChange,
    onClose,
    onReadMore,
    promotionEnd,
  };
}
